import {createI18n, type I18n} from "vue-i18n";
import {ui} from "@/i18n/ui.ts";

export let i18n:I18n|null = null;

export function geti18n() {

    if(!i18n) {
        i18n = createI18n({
            legacy: false,
            globalInjection: true,
            messages: ui,
        });
    }

    // i18n.global.locale.value = 'ja';

    return i18n;
}